

























import { Component } from 'vue-property-decorator';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/mixins/UsesFarmId';
import OverviewTable from '@/mixins/OverviewTable';
import Overview from '@/components/Overview.vue';
import ZetaHubzone from '@/core/interfaces/ZetaHubzone';
import ZetaHubZoneForm from '@/components/farms/ZetaHubZoneForm.vue';

@Component({
    components: { ZetaHubZoneForm, Overview },
})
export default class FarmZetaHubZonesView extends mixins<UsesFarmId, OverviewTable<ZetaHubzone>>(UsesFarmId, OverviewTable) {
    @Action('deleteZetaHubZone')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('zetaHubZonesByFarm')
    zetaHubZonesByFarm!: (id: number) => ZetaHubzone[];

    editZetaHubZone: Partial<ZetaHubzone> | null = null;

    get zetaHubZones(): ZetaHubzone[] {
        return this.zetaHubZonesByFarm(Number(this.farmId));
    }

    get columns(): LelyTableColumnsConfig<ZetaHubzone> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Name',
                value: 'name',
            },
        ];
    }

    showZetaHubZone(zetaHubzone: ZetaHubzone): void {
        this.editZetaHubZone = zetaHubzone;
        this.$bvModal.show('zeta-hub-zone-form');
    }

    zetaHubZonesUpdated(): void {
        this.$bvModal.hide('zeta-hub-zone-form');
        this.editZetaHubZone = null;
    }

    addZetaHubZone(): void {
        this.editZetaHubZone = {
            farm_id: this.farmId,
        } as Partial<ZetaHubzone>;

        this.$bvModal.show('zeta-hub-zone-form');
    }
}

