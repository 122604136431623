import RootState from '@/store/rootState';
import Barn from '@/core/interfaces/Barn';
import Fence from '@/core/interfaces/Fence';
import Farm from '@/core/interfaces/Farm';
import VehicleType from '@/core/interfaces/VehicleType';
import Vehicle from '@/core/interfaces/Vehicle';
import User from '@/core/interfaces/User';
import AvailableVehicleType from '@/core/interfaces/AvailableVehicleType';
import FeedType from '@/core/interfaces/FeedType';
import VehicleAction from '@/core/interfaces/VehicleAction';
import AvailableVehicleTypeAction from '@/core/interfaces/AvailableVehicleTypeAction';
import Application from '@/core/interfaces/Application';
import Locale from '@/core/interfaces/Locale';
import ArbiterZone from '@/core/interfaces/ArbiterZone';
import TimeZone from '@/core/interfaces/TimeZone';
import UserGroup from '@/core/interfaces/UserGroup';
import FarmGroup from '@/core/interfaces/FarmGroup';
import Role from '@/core/interfaces/Role';
import UserUserGroup from '@/core/interfaces/UserUserGroup';
import VehicleTypeUserGroup from '@/core/interfaces/VehicleTypeUserGroup';
import FarmGroupUserGroup from '@/core/interfaces/FarmGroupUserGroup';
import RoleUserGroup from '@/core/interfaces/RoleUserGroup';
import FarmFarmGroup from '@/core/interfaces/FarmFarmGroup';
import arrayUnique from 'array-unique';
import ZetaHubzone from '@/core/interfaces/ZetaHubzone';

const sortAZ = <T>(values: T[], key: keyof T): T[] => values
    .sort((a, b) => (String(a[key])).localeCompare(String(b[key])));

export const farms = (state: RootState): Farm[] => sortAZ(state.farms, 'name');

export const users = (state: RootState): User[] => sortAZ(state.users, 'username');

export const vehicles = (state: RootState): Vehicle[] => sortAZ(state.vehicles, 'name');

export const vehicleTypes = (state: RootState): VehicleType[] => sortAZ(state.vehicleTypes, 'name');

export const timeZones = (state: RootState): TimeZone[] => sortAZ(state.timeZones, 'name');

export const barnsByFarm = (state: RootState) => (farmId: number): Barn[] => state
    .barns
    .filter(barn => barn.farm_id === farmId);

export const vehicleTypeById = (state: RootState) => (vehicleTypeId: number): VehicleType | null => state
    .vehicleTypes
    .find(vehicleType => vehicleType.id === vehicleTypeId) || null;

export const farmById = (state: RootState) => (vehicleTypeId: number): Farm | null => state
    .farms
    .find(farm => farm.id === vehicleTypeId) || null;

export const barnById = (state: RootState) => (vehicleTypeId: number): Barn | null => state
    .barns
    .find(barn => barn.id === vehicleTypeId) || null;

export const fenceById = (state: RootState) => (vehicleTypeId: number): Fence | null => state
    .fences
    .find(fence => fence.id === vehicleTypeId) || null;

export const userById = (state: RootState) => (userId: number): User | null => state
    .users
    .find(user => user.id === userId) || null;

export const fencesByBarn = (state: RootState) => (barnId: number): Fence[] => state
    .fences
    .filter(fence => fence.barn_id === barnId);

export const vehiclesByFarm = (state: RootState) => (farmId: number): Vehicle[] => state
    .vehicles
    .filter(vehicle => vehicle.farm_id === farmId);

export const vehiclesByFarmAndVehicleType = (state: RootState, getters: any) => (farmId: number, vehicleTypeId: number): Vehicle[] => (getters
    .vehiclesByFarm(farmId) as Vehicle[])
    .filter(vehicle => vehicle.vehicle_type_id === vehicleTypeId);

export const availableVehicleTypesByFarm = (state: RootState) => (farmId: number): AvailableVehicleType[] => state
    .availableVehicleTypes
    .filter(availableVehicleType => availableVehicleType.farm_id === farmId);

export const vehicleTypesByFarm = (state: RootState, getters: any) => (farmId: number): VehicleType[] => sortAZ(getters
    .availableVehicleTypesByFarm(farmId)
    .map((availableVehicleType: AvailableVehicleType) => vehicleTypeById(state)(availableVehicleType.vehicle_type_id) as VehicleType), 'name');

export const fencesByFarm = (state: RootState) => (farmId: number): Fence[] => barnsByFarm(state)(farmId)
    .flatMap(barn => fencesByBarn(state)(barn.id));

export const feedTypesByFarm = (state: RootState) => (farmId: number): FeedType[] => sortAZ(state
    .feedTypes
    .filter(feedType => feedType.farm_id === farmId), 'name');

export const vehicleActions = (state: RootState): VehicleAction[] => sortAZ(state
    .vehicleActions, 'name');

export const availableActionsByAvailableVehicleType = (state: RootState) => (availableVehicleType: number): AvailableVehicleTypeAction[] => state
    .availableVehicleTypeActions
    .filter(action => action.farm_available_vehicle_type_id === availableVehicleType);

export const vehicleActionById = (state: RootState) => (vehicleActionId: number): VehicleAction | null => state
    .vehicleActions
    .find(vehicleAction => vehicleAction.id === vehicleActionId) || null;

export const applications = (state: RootState): Application[] => sortAZ(state
    .applications, 'code');

export const applicationById = (state: RootState) => (applicationId: number): Application | null => state
    .applications
    .find(applications => applications.id === applicationId) || null;

export const locales = (state: RootState): Locale[] => sortAZ(state
    .locales, 'name');

export const localeById = (state: RootState) => (localeId: number): Locale | null => state
    .locales
    .find(locale => locale.id === localeId) || null;

export const arbiterZonesByFarm = (state: RootState) => (farmId: number): ArbiterZone[] => {
    const barnIdsInFarm = barnsByFarm(state)(farmId).map(barn => barn.id);

    return sortAZ(state.arbiterZones, 'name')
        .filter(arbiterZone => arbiterZone.farm_id === farmId || (arbiterZone.barn_id && barnIdsInFarm.includes(arbiterZone.barn_id)));
};

export const zetaHubZonesByFarm = (state: RootState) => (farmId: number): ZetaHubzone[] => sortAZ(state.zetaHubZones, 'name')
    .filter(zetaHubZone => zetaHubZone.farm_id === farmId);

export const userGroups = (state: RootState) => sortAZ(state.userGroups, 'name');

export const farmGroups = (state: RootState) => sortAZ(state.farmGroups, 'name');

export const roles = (state: RootState) => sortAZ(state.roles, 'name');

export const userGroupById = (state: RootState) => (userGroupId: number): UserGroup => state.userGroups
    .find(userGroup => userGroup.id === userGroupId) as UserGroup;

export const farmGroupById = (state: RootState) => (farmGroupId: number): FarmGroup => state.farmGroups
    .find(farmGroup => farmGroup.id === farmGroupId) as FarmGroup;

export const userUserGroupsByUser = (state: RootState) => (userId: number): UserUserGroup[] => state.userUserGroups
    .filter(userUserGroup => userUserGroup.user_id === userId);

export const userGroupsByUser = (state: RootState, getters: any) => (userId: number): UserGroup[] => sortAZ((getters
    .userUserGroupsByUser(userId) as UserUserGroup[])
    .map(userUserGroup => getters.userGroupById(userUserGroup.user_group_id)), 'name');

export const roleUserGroupByUserGroup = (state: RootState) => (userGroupId: number): RoleUserGroup[] => state.roleUserGroups
    .filter(roleGroup => roleGroup.user_group_id === userGroupId);

export const rolesByUserGroup = (state: RootState, getters: any) => (userGroupId: number): UserGroup[] => sortAZ((getters
    .roleUserGroupByUserGroup(userGroupId) as RoleUserGroup[])
    .map(roleGroup => state.roles.find(role => role.id === roleGroup.role_id) as Role), 'name');

export const vehicleTypeUserGroupsByUserGroup = (state: RootState) => (userGroupId: number): VehicleTypeUserGroup[] => state
    .vehicleTypeUserGroups.filter(vehicleTypeUserGroup => vehicleTypeUserGroup.user_group_id === userGroupId);

export const vehicleTypesByUserGroup = (state: RootState, getters: any) => (userGroupId: number): VehicleType[] => sortAZ((getters
    .vehicleTypeUserGroupsByUserGroup(userGroupId) as VehicleTypeUserGroup[])
    .map(vehicleTypeUserGroup => getters.vehicleTypeById(vehicleTypeUserGroup.vehicle_type_id)) as VehicleType[], 'name');

export const farmGroupUserGroupsByUserGroup = (state: RootState) => (userGroupId: number): FarmGroupUserGroup[] => state
    .farmGroupUserGroups.filter(farmGroupUserGroup => farmGroupUserGroup.user_group_id === userGroupId);

export const farmGroupsByUserGroup = (state: RootState, getters: any) => (userGroupId: number): FarmGroup[] => sortAZ((getters
    .farmGroupUserGroupsByUserGroup(userGroupId) as FarmGroupUserGroup[])
    .map(farmGroupUserGroup => getters.farmGroupById(farmGroupUserGroup.farm_group_id)) as FarmGroup[], 'name');

export const farmFarmGroupsByFarm = (state: RootState) => (farmId: number): FarmFarmGroup[] => state
    .farmFarmGroups.filter(farmFarmGroup => farmFarmGroup.farm_id === farmId);

export const farmFarmGroupsByFarmGroup = (state: RootState) => (farmGroupId: number): FarmFarmGroup[] => state
    .farmFarmGroups.filter(farmFarmGroup => farmFarmGroup.farm_group_id === farmGroupId);

export const farmGroupsByFarm = (state: RootState, getters: any) => (farmId: number): FarmGroup[] => sortAZ((getters
    .farmFarmGroupsByFarm(farmId) as FarmFarmGroup[])
    .map(farmFarmGroup => getters.farmGroupById(farmFarmGroup.farm_group_id)), 'name');

export const farmsByFarmGroup = (state: RootState, getters: any) => (farmGroupId: number): Farm[] => state
    .farmFarmGroups
    .filter(farmFarmGroup => farmFarmGroup.farm_group_id === farmGroupId)
    .map(farmFarmGroup => getters.farmById(farmFarmGroup.farm_id));

export const rolesByUser = (state: RootState, getters: any) => (userId: number): Role[] => sortAZ(arrayUnique(
    (getters
        .userGroupsByUser(userId) as UserGroup[])
        .flatMap(userGroup => getters.rolesByUserGroup(userGroup.id)),
), 'name');

export const userUserGroupsByUserGroup = (state: RootState) => (userGroupId: number): UserUserGroup[] => state.userUserGroups
    .filter(userUserGroup => userUserGroup.user_group_id === userGroupId);

export const usersByUserGroup = (state: RootState, getters: any) => (userGroupId: number): User[] => sortAZ((getters
    .userUserGroupsByUserGroup(userGroupId) as UserUserGroup[])
    .map(userUserGroup => getters.userById(userUserGroup.user_id)) as User[], 'username');

export const userGroupsByFarmGroup = (state: RootState, getters: any) => (farmGroupId: number): UserGroup[] => state
    .farmGroupUserGroups
    .filter(farmGroupUserGroup => farmGroupUserGroup.farm_group_id === farmGroupId)
    .map(farmGroupUserGroup => getters.userGroupById(farmGroupUserGroup.user_group_id));

export const farmsByUserGroup = (state: RootState, getters: any) => (userGroupId: number): Farm[] => state
    .farmGroupUserGroups
    .filter(farmGroupUserGroup => farmGroupUserGroup.user_group_id === userGroupId)
    .flatMap(farmGroupUserGroup => getters.farmsByFarmGroup(farmGroupUserGroup.farm_group_id));

export const usersByFarm = (state: RootState, getters: any) => (farmId: number): User[] => (
    getters.users as User[])
    .filter(user => user.farm_id === farmId);
