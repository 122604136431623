












































































































import { Component, Watch } from 'vue-property-decorator';
import Vehicle from '@/core/interfaces/Vehicle';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import Farm from '@/core/interfaces/Farm';
import VehicleType from '@/core/interfaces/VehicleType';
import Barn from '@/core/interfaces/Barn';
import ZetaHubzone from '@/core/interfaces/ZetaHubzone';

@Component({
    components: { FormFooter },
})
export default class VehicleForm extends FormBase<Vehicle> {
    @Action('saveVehicle')
    saveAction!: (barn: Partial<Vehicle>) => Promise<Vehicle>;

    @Getter('farms')
    farms!: Farm[];

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('barnsByFarm')
    barnsByFarm!: (id: number) => Barn[];

    @Getter('zetaHubZonesByFarm')
    zetaHubZonesByFarm!: (id: number) => ZetaHubzone[];

    farmId = 0;

    barnId: number | null = null;

    ZetaIsGrouplamp = false;

    zetaHubZoneId: number | null = null;

    vehicleTypeId = 0;

    name = '';

    resetAccessToken = false;

    sim = false;

    mfrId: number | null = null;

    mfrVehicleTypeId = 0;

    zetaVehicleTypeId = 0;

    btClassic = false;

    mounted(): void {
        this.mfrVehicleTypeId = this.vehicleTypes.find(vt => vt.name === 'mfr')?.id || 0;
        this.zetaVehicleTypeId = this.vehicleTypes.find(vt => ['aurora', 'zeta'].includes(vt.name))?.id || 0;
    }

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.farmId = this.initial.farm_id || 0;
        this.barnId = this.initial.barn_id || null;
        this.vehicleTypeId = this.initial.vehicle_type_id || 0;
        this.sim = this.initial.sim || false;
        this.mfrId = this.initial.mfr_id || null;
        this.btClassic = this.initial.bt_classic || false;
        this.zetaHubZoneId = this.initial.zeta_hub_zone_id || null;
        this.ZetaIsGrouplamp = this.initial.zeta_grouplamp || false;
    }

    get parseForm(): Partial<Vehicle> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            farm_id: this.farmId,
            barn_id: this.barnId,
            vehicle_type_id: this.vehicleTypeId,
            access_token: this.resetAccessToken ? null : this.initial.access_token,
            sim: Boolean(this.sim),
            mfr_id: this.vehicleTypeId === this.mfrVehicleTypeId ? this.mfrId : null,
            bt_classic: Boolean(this.btClassic),
            zeta_hub_zone_id: this.vehicleTypeId === this.zetaVehicleTypeId ? this.zetaHubZoneId : null,
            zeta_grouplamp: Boolean(this.ZetaIsGrouplamp),
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }

    @Watch('farmId')
    resetBarn(): void {
        this.barnId = null;
    }

    get zetaHubZones(): ZetaHubzone[] {
        return this.zetaHubZonesByFarm(this.farmId);
    }

    get zetaGrouplampOptions(): { text: string; value: boolean }[] {
        return [
            { text: 'Hub', value: false },
            { text: 'Group lamp', value: true },
        ];
    }
}

