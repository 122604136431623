import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import FarmsView from '@/views/farms/FarmsView.vue';
import VehicleTypesView from '@/views/VehicleTypesView.vue';
import UsersView from '@/views/users/UsersView.vue';
import FarmFormView from '@/views/farms/FarmFormView.vue';
import FarmView from '@/views/farms/FarmView.vue';
import FarmBarnsView from '@/views/farms/FarmBarnsView.vue';
import VehiclesView from '@/views/VehiclesView.vue';
import FarmFeedTypesView from '@/views/farms/FarmFeedTypesView.vue';
import FarmVehicleTypesView from '@/views/farms/FarmVehicleTypesView.vue';
import VehicleActionsView from '@/views/VehicleActionsView.vue';
import ApplicationsView from '@/views/translations/ApplicationsView.vue';
import LocalesView from '@/views/translations/LocalesView.vue';
import TranslationsView from '@/views/translations/TranslationsView.vue';
import FarmArbiterZonesView from '@/views/farms/FarmArbiterZonesView.vue';
import { LelyAuth } from '@tec/frontend-vue-shared';
import UserGroupsView from '@/views/users/UserGroupsView.vue';
import FarmGroupsView from '@/views/farms/FarmGroupsView.vue';
import UserRolesView from '@/views/users/UserRolesView.vue';
import FarmZetaHubZonesView from '@/views/farms/FarmZetaHubZonesView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: LoginView,
        name: 'login',
        meta: {
            guest: true,
        },
    },
    {
        path: '/',
        redirect: () => ({ name: 'farms.farms' }),
    },
    {
        path: '/farms',
        name: 'farms.index',
        component: { render: h => h('router-view') },
        redirect: () => ({ name: 'farms.farms.index' }),
        children: [
            {
                path: 'farms',
                name: 'farms.farms',
                component: { render: h => h('router-view') },
                redirect: () => ({ name: 'farms.farms.index' }),
                children: [
                    {
                        path: '',
                        name: 'farms.farms.index',
                        component: FarmsView,
                    },
                    {
                        path: ':farmId',
                        name: 'farms.farms.item',
                        component: FarmView,
                        props: true,
                        children: [
                            {
                                path: 'form',
                                name: 'farms.item.form',
                                component: FarmFormView,
                                props: true,
                            },
                            {
                                path: 'vehicle-types',
                                name: 'farms.item.vehicle-types',
                                component: FarmVehicleTypesView,
                                props: true,
                            },
                            {
                                path: 'barns',
                                name: 'farms.item.barns',
                                component: FarmBarnsView,
                                props: true,
                            },
                            {
                                path: 'feed-types',
                                name: 'farms.item.feed-types',
                                component: FarmFeedTypesView,
                                props: true,
                            },
                            {
                                path: 'arbiter-zone',
                                name: 'farms.item.arbiter-zones',
                                component: FarmArbiterZonesView,
                                props: true,
                            },
                            {
                                path: 'zeta-hub-zone',
                                name: 'farms.item.zeta-hub-zones',
                                component: FarmZetaHubZonesView,
                                props: true,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'groups',
                name: 'farms.groups',
                component: FarmGroupsView,
            },
        ],
    },
    {
        path: '/users',
        name: 'users.index',
        component: { render: h => h('router-view') },
        redirect: () => ({ name: 'users.users' }),
        children: [
            {
                path: 'users',
                name: 'users.users',
                component: UsersView,
            },
            {
                path: 'groups',
                name: 'users.groups',
                component: UserGroupsView,
            },
            {
                path: 'roles',
                name: 'users.roles',
                component: UserRolesView,
            },
        ],
    },
    {
        path: '/vehicles',
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'vehicles.index',
                component: VehiclesView,
            },
        ],
    },
    {
        path: '/vehicle-types',
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'vehicle-types.index',
                component: VehicleTypesView,
            },
        ],
    },
    {
        path: '/vehicle-actions',
        component: { render: h => h('router-view') },
        children: [
            {
                path: '',
                name: 'vehicle-actions.index',
                component: VehicleActionsView,
            },
        ],
    },
    {
        path: '/translations',
        name: 'translations.index',
        component: TranslationsView,
        redirect: () => ({ name: 'translations.applications' }),
        children: [
            {
                path: 'applications',
                name: 'translations.applications',
                component: ApplicationsView,
            },
            {
                path: 'locales',
                name: 'translations.locales',
                component: LocalesView,
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.guest)) {
        if (!LelyAuth.isAuthenticated()) {
            return next();
        }
        return next({ name: 'farms.index' });
    }

    if (LelyAuth.isAuthenticated()) {
        return next();
    }

    return next({ name: 'login' });
});

export default router;
