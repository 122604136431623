import RootState from '@/store/rootState';

export default {
    applications: [],
    arbiterZones: [],
    zetaHubZones: [],
    availableVehicleTypeActions: [],
    availableVehicleTypes: [],
    barns: [],
    errors: [],
    farms: [],
    feedTypes: [],
    fences: [],
    locales: [],
    timeZones: [],
    users: [],
    vehicleActions: [],
    vehicleTypes: [],
    vehicles: [],
    userGroups: [],
    userUserGroups: [],
    farmGroups: [],
    farmFarmGroups: [],
    farmGroupUserGroups: [],
    roles: [],
    roleUserGroups: [],
    vehicleTypeUserGroups: [],
} as RootState;
